import { Controller } from "@hotwired/stimulus";
import { get } from '@rails/request.js';

// Connects to data-controller="staff-leave-request-form"
export default class extends Controller {
  static targets = ['staffSelect', 'leaveStartDate', 'leaveType', 'unpaidCheckbox'];

  changeStaffLeaveMinimumStartDate(event) {
    const currentDate = new Date().toUTCString();
    const selectedEmployeeId = event.target.value;
    const staffLeaveDateFrom = this.leaveStartDateTarget._flatpickr;
    const currentEmployeeRole = this.staffSelectTarget.dataset.currentEmployeeRole
    const currentEmployeeId = this.staffSelectTarget.dataset.currentEmployeeId

    if (currentEmployeeRole === 'admin' || currentEmployeeRole === 'manager') {
      staffLeaveDateFrom.set('minDate', null);
    } else if (currentEmployeeRole === 'approver' && currentEmployeeId != selectedEmployeeId) {
      staffLeaveDateFrom.set('minDate', null);
    } else {
      staffLeaveDateFrom.set('minDate', currentDate);
    }   
  }

  changeLeaveStats(event) {
    const selectedEmployeeId = event.target.value;
    const subdomain = this.staffSelectTarget.dataset.currentAccountSubdomain;
    
    get(`/${subdomain}/staff_leave/leave_statistics?employee_id=${selectedEmployeeId}`, {
      responseKind: 'turbo-stream'
    })
  }

  showCriteriaContent() {
    const subdomain = this.staffSelectTarget.dataset.currentAccountSubdomain
    const leave_type = this.leaveTypeTarget.options[this.leaveTypeTarget.selectedIndex].text;

    get(`/${subdomain}/staff_leave/leave_criteria?leave_type=${leave_type}`, {
      responseKind: 'turbo-stream'
    })
  }

  changeUnpaidCheckboxVisibility(event) {
    const selectedEmployeeId = event.target.value;
    const currentEmployeeId = this.staffSelectTarget.dataset.currentEmployeeId;
    const currentEmployeeRole = this.staffSelectTarget.dataset.currentEmployeeRole;
    const unpaid_checkbox_HTML= `
    <div class="flex items-center mb-4">
      <input class="checkbox checkbox-secondary rounded-lg cursor-pointer" type="checkbox" value=true name="leave_request[unpaid]" id="leave_request_unpaid">
      <label class="checkbox-label" for="leave_request_unpaid">${this.unpaidCheckboxTarget.dataset.unpaidCheckboxLabel}</label>
    </div>
    `;

    if (['admin', 'manager', 'approver'].includes(currentEmployeeRole) && currentEmployeeId != selectedEmployeeId) {
      this.unpaidCheckboxTarget.innerHTML = unpaid_checkbox_HTML;
    } else {
      this.unpaidCheckboxTarget.innerHTML = '';
    }
  }
}
