import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

// Connects to data-controller="ts--select"
export default class extends Controller {
  connect() {
    const select = new TomSelect(this.element);
    let lastSelectedValue = select.items[0];

    // Clear options when typing
    select.on('focus', () => {
      select.clear();
    });

    // Store the last selected value whenever an option is selected
    select.on('item_add', (value) => {
      lastSelectedValue = value;
    });

    // Assign first option when the user leaves the element without selecting an option
    select.on('blur', () => {
      if (select.items.length === 0) {
        select.addItem(lastSelectedValue);
      }
    });

    // Remove cursor when an option is selected
    select.on('dropdown_close', () => {
      select.blur();
    });
  }
}
