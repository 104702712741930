import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    accountSubdomain: String
  }

  redirect_to_stripe_portal(event) {
    const subdomain = this.accountSubdomainValue;
    const billing_action = event.target.dataset.billingAction;

    fetch(`/${subdomain}/billing/portal`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Response was not ok');
        }
        return response.json();
      })
      .then(data => {
        let sessionUrl = data.session_url;

        switch (billing_action) {
          case 'update':
            sessionUrl += '/subscriptions/' + data.payment_processor_id + '/update';
            break;
          case 'cancel':
            sessionUrl += '/subscriptions/' + data.payment_processor_id + '/cancel';
            break;
          case 'reactivate':
            sessionUrl += '/subscriptions/' + data.payment_processor_id + '/reactivate';
            break;
          case 'payment-method':
            sessionUrl += '/payment-methods';
            break;
          default:
            break;
        }
          
        window.open(sessionUrl, '_blank');
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
}
