import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['startDate', 'endDate', 'cycleDuration']

  setEndDate() {
    const start_date = new Date(this.startDateTarget.value)
    const cycle_duration = Number(this.cycleDurationTarget.value)
    let end_date = start_date

    end_date.setFullYear(end_date.getFullYear() + cycle_duration)
    end_date.setDate(end_date.getDate() - 1)

    this.endDateTarget.value = end_date.toISOString().split('T')[0];
  }
}
