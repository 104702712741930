import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const w = 500,
    h = 450,
    r = 180,
    inner = 200 / 2,
    color = d3
    .scaleOrdinal()
    .range([
      "#013a70",
      "#0274e1",
      "#0281fa",
      "#b1d8fd",
      "#e6f2ff",
    ]);
    const data = [
      {label: "Paid", value: 40, color: "#013a70"},
      {label: "Educational", value: 32, color: "#0274e1"},
      {label: "Medical", value: 32, color: "#0281fa"},
      {label: "Unpaid", value: 32, color: "#b1d8fd"},
      {label: "Maternity", value: 28, color: "#e6f2ff"},
    ];
    var svg = d3
    .select("#donut-pie-chart")
    .append("div")
    .classed("svg-container", true) //container class to make it responsive
    .append("svg") // Place the chart in 'pie-chart-div'
    .classed("svg-content-responsive", true)
    .attr("width", "100%")
    .attr("height", "100%")

    var vis = svg
    .data([data])
    .attr("width", "100%")
    .attr("height", "100%")
    .attr("viewBox", -w / 2 + " " + -h / 2 + " " + w + " " + h)
    var arc = d3.arc().innerRadius(inner).outerRadius(r);
    var arcOver = d3
    .arc()
    .innerRadius(inner + 5)
    .outerRadius(r + 5);
    var pie = d3.pie().value(function (d) {
      return d.value;
    });

    const div = d3.select("body").append("div")
    .attr("class", "piechart-tooltip")
    .style("opacity", 0);

    var arcs = vis
    .selectAll("g.slice")
    .data(pie)
    .enter()
    .append("svg:g")
    .attr("class", "slice")
    .on("mouseover", function (event, d) {
      d3.select(this)
      .select("path")
      .transition()
      .duration(200)
      .attr("d", arcOver);
      div.transition()
      .duration(200)
      .style("opacity", .9)
      div.html(`${d.value}% <br/> ${d.data.label}`)
      .style("left", (event.pageX) + "px")
      .style("top", (event.pageY - 28) + "px").style("font-weight", "bold")
    })
    .on("mouseout", function (d) {
      d3.select(this)
      .select("path").style("shadow", "#000000")
      .transition()
      .duration(100)
      .attr("d", arc);

      div.transition()
      .duration(500)
      .style("opacity", 0);
    });
    arcs
    .append("svg:path")
    .style("fill", function (d, i) {
      return color(i);
    })
    .attr("d", arc);

    //Initialize legend
    var legendItemSize = 12;
    var legendSpacing = 4;
    var xOffset = 15;
    var yOffset = 15;
    var legend = d3
    .select('#doughnut-legend')
    .append('svg').attr("height", "15em").attr("width", "7em")
    .selectAll('.legendItem')
    .data(data);

    //Create legend items
    legend
    .enter()
    .append('circle')
    .attr('class', 'legendItem')
    .attr('width', legendItemSize)
    .attr('height', legendItemSize).attr("r", 5)
    .style('fill', d => d.color)
    .attr('transform',
    (d, i) => {
      var x = xOffset;
      var y = yOffset + (legendItemSize + legendSpacing) * i * 3;
      return `translate(${x}, ${y})`;
    });

    //Create legend labels
    legend
    .enter()
    .append('text')
    .attr('x', xOffset + legendItemSize + 12)
    .attr('y', (d, i) => yOffset + (legendItemSize + legendSpacing) * i * 3)
    .text(d => d.label).style("font-size", "12px").style("font-weight", "bold").style("color", "#121212").style("opacity", "0.6").style("font", "Manrope")

    //Create legend labels
    legend
    .enter()
    .append('text')
    .attr('x', xOffset + legendItemSize + 15)
    .attr('y', (d, i) => (yOffset + xOffset + legendSpacing) + i * 50)
    .text(d => ` ${d.value}%`).style("font-size", "12px").style("color", "#475569").style("opacity", "0.6")
  }
}
