import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "preview", "imageLabel"];

  preview() {
    let file = this.inputTarget.files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      this.previewTarget.src = reader.result;
      this.imageLabelTarget.innerHTML = this.imageLabelTarget.dataset.editImageLabel;
    }

    if (file) {
      reader.readAsDataURL(file);
    } else {
      this.previewTarget.src = "";
    }
  }
}
