import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bulk-upload-progress"
export default class extends Controller {
  static targets = ["progress"]

  connect() {
     setInterval(() => this.query(this.progressTarget), 10000);
  }

  query(element) {
    element.click()
  }
}
