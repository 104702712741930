import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    document.body.classList.add("overflow-hidden");
    setTimeout(() => {
      this.dismiss();
    }, 3000)
  }

  dismiss() {
    this.element.classList.add("animate__slideOutRight");
    setTimeout(() => {
      this.element.remove();
      document.body.classList.remove("overflow-hidden");
    }, 500);
  }
} 

