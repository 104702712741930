import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
this.pdfFileType();
  }

  pdfFileType = () => {
    const docType =   document.getElementsByClassName("document");
    docType[0].accept = [".pdf",".jpg",".jpeg"];
  }

  csvFileType = () => {
    const csvFileType = document.getElementsByClassName("csv-file");
    csvFileType[0].accept = [".csv"]
  }

}
