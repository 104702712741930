import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['contactLink'];

  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    const tabIndex = urlParams.get('tab');

    if (tabIndex === 'contact') {
      console.log(this.contactLinkTarget);
      this.switch_to_contact_tab();
    }
  }

  switch_to_contact_tab() {
    if (this.contactLinkTarget) {
      this.contactLinkTarget.click();
    }
  }
}