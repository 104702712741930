
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["countdown", "resendContent"];

  connect() {
    this.startCountdown();
  }

  startCountdown() {
    let seconds = this.countdownTarget.dataset.countdownSeconds;
    const countdownInterval = setInterval(() => {
      this.countdownTarget.textContent = seconds;
      seconds--;

      if (seconds < 0) {
        const registration_id = window.location.href.split("/").slice(-2)[0];
        clearInterval(countdownInterval);
        this.resendContentTarget.innerHTML = `<a href="/registrations/${registration_id}/resend_code">${this.resendContentTarget.dataset.resendAgain}</a>`;
      }
    }, 1000);
  }
}
