import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="password-toggle"
export default class extends Controller {
  static targets = ["passwordField", "icon"]

  connect(){
    this.openEyeIcon = this.element.dataset.openEye;
    this.closeEyeIcon = this.element.dataset.closeEye;
  }

  toggle(event) {
    const button = event.currentTarget;
    const password_field = button.previousElementSibling;

    if (password_field.type === "password") {
      password_field.type = "text";
      this.iconTarget.src = this.closeEyeIcon;
    } else {
      password_field.type = "password";
      this.iconTarget.src = this.openEyeIcon;
    }
  }
}
