import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form'];

  filter(event) {
    if (!event.target.value) return;
    
    this._submit()
  }

  _submit() {
    this.formTarget.requestSubmit();
  }
}