import {Controller} from "@hotwired/stimulus";
import flatpickr from "flatpickr";

// Connects to data-controller="fp--datepicker"
export default class extends Controller {
  flatpicker = flatpickr(this.element);

  connect() {
    this.staffLeaveStartDate();
    this.newLeaveStartDate();
    this.newLeaveStaffStartDate();
    this.newLeaveEndDate();
  }


  newLeaveStartDate = (event) => {
    const currentDate = new Date().toUTCString();
    const dateFrom= document.getElementsByClassName("new-leave-start-date")[0].offsetParent._flatpickr;
    dateFrom.set("minDate", currentDate)
    const leaveFrom= document.getElementsByClassName("leave-from-date");
    leaveFrom[0].innerHTML = dateFrom._positionElement.value;
    return dateFrom._positionElement.value;
  }

  newLeaveEndDate = () => {
  const dateTo= document.getElementsByClassName("new-leave-end-date")[0].offsetParent._flatpickr;
    dateTo.set("minDate", this.newLeaveStartDate());
    document.getElementsByClassName("leave-to-date")[0].innerHTML = dateTo._positionElement.value
  }

  newLeaveStaffStartDate = (event) => {
    const currentDate = new Date().toUTCString();
    const dateFrom= document.getElementsByClassName("new-leave-staff-start-date")[0].offsetParent._flatpickr;
    dateFrom.set("minDate", currentDate)
    const leaveFrom= document.getElementsByClassName("staff-leave-from");
    leaveFrom[0].innerHTML = dateFrom._positionElement.value;
    return dateFrom._positionElement.value;
  }

  newLeaveStaffEndDate = () => {
      const dateTo= document.getElementsByClassName("new-leave-staff-end-date")[0].offsetParent._flatpickr;
    dateTo.set("minDate", this.newLeaveStaffStartDate());
    document.getElementsByClassName("staff-leave-to")[0].innerHTML = dateTo._positionElement.value
  }

  dateFromData = () => {
    const fp = document.querySelector("#leave_request_date_to")._flatpickr;
    fp.set("minDate", fpdateFrom)
  }

  staffLeaveStartDate = () => {
      const currentDate = new Date().toUTCString();
    const staffLeaveDateFrom = document.getElementsByClassName("staff-leave-start-date")[0]._flatpickr;
    const selected_employee_div = document.querySelector('.ts-control').querySelector('.item');
    const selectedEmployeeId = selected_employee_div.getAttribute('data-value');
    const currentEmployeeRole = this.element.dataset.currentEmployeeRole
    const currentEmployeeId = this.element.dataset.currentEmployeeId

    if (currentEmployeeRole === 'admin' || currentEmployeeRole === 'manager') {
      staffLeaveDateFrom.set('minDate', null);
    } else if (currentEmployeeRole === 'approver' && currentEmployeeId != selectedEmployeeId) {
      staffLeaveDateFrom.set('minDate', null);
    } else {
      staffLeaveDateFrom.set('minDate', currentDate);
    }   
    return staffLeaveDateFrom._positionElement.value;
  }

  staffLeaveEndDate = () => {
    const staffLeaveDateTo = document.querySelector(".staff-leave-end-date")._flatpickr;
    staffLeaveDateTo.set("minDate", this.staffLeaveStartDate())
     }   

     
}
