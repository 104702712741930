import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["data"];

  connect() {
    // set the dimensions and margins of the graph
    let margin = {top: 20, right: 60, bottom: 30, left: 10},
    width = this.element.offsetWidth - margin.left - margin.right,
    height = 190 - margin.top - margin.bottom;

    // set the ranges
    let x = d3.scaleBand()
    .range([0, width])
    .padding(0.75);
    let y = d3.scaleLinear()
    .range([height, 0])

    const svg = d3.select("#bar-chart").attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform",
    "translate(" + margin.left + "," + margin.top + ")");
    // const sample = [
    //   {
    //     date: 1,
    //     value: 12
    //   },
    //   {
    //     date: 2,
    //     value: 4
    //   },
    //   {
    //     date: 3,
    //     value: 16
    //   },
    //   {
    //     date: 4,
    //     value: 19
    //   },
    //   {
    //     date: 5,
    //     value: 1
    //   },
    //   {
    //     date: 6,
    //     value: 4
    //   },
    //   {
    //     date: 7,
    //     value: 12
    //   },
    //   {
    //     date: 8,
    //     value: 12
    //   },
    //   {
    //     date: 9,
    //     value: 4
    //   },
    //   {
    //     date: 10,
    //     value: 16
    //   },
    //   {
    //     date: 11,
    //     value: 19
    //   },
    //   {
    //     date: 12,
    //     value: 1
    //   },
    //   {
    //     date: 13,
    //     value: 4
    //   },
    //   {
    //     date: day,
    //     value: 12
    //   },
    // ]
    const sample = JSON.parse(this.dataTarget.dataset.leaves)
    // Scale the range of the data in the domains
    x.domain(sample.map(function (d) {
      return d.date;
    }));

    let maxLeavePerDay = Math.max(...sample.map(dailyReport => dailyReport.value))
    maxLeavePerDay = maxLeavePerDay == 0 ? 1 : maxLeavePerDay
    y.domain([0, d3.max(sample, function (d) {
      return maxLeavePerDay;
    })]);

    const div = d3.select("body").append("div")
    .attr("class", "tooltip")
    .style("opacity", 0);

    // append the rectangles for the bar chart
    svg.selectAll(".bar")
    .data(sample)
    .enter().append("rect")
    .attr("class", "bar")
    .attr("x", function (d) {
      return x(d.date);
    })
    .attr("width", x.bandwidth())
    .attr("y", function (d) {
      return y(d.value);
    })
    .attr("height", function (d) {
      return height - y(d.value);
    }).on("mouseover", function (event, d) {
      div.transition()
      .duration(200)
      .style("opacity", .9)
      div.html(d.value)
      .style("left", (event.pageX) + "px")
      .style("top", (event.pageY - 28) + "px");
      d3.select(this).style("fill", "#0281fa");
    })
    .on("mouseout", function (d) {
      div.transition()
      .duration(500)
      .style("opacity", 0);
      d3.select(this).style("fill", "#b1d8fd");
    })

    // add the x Axis
    svg.append("g")
    .attr("transform", "translate(0," + height + ")").attr("class", "numbers")
    .call(d3.axisBottom(x))

    svg.append('line')
    .style('stroke', '#b1d8fd')
    .attr('x1', 0)
    .attr('y1', "80")
    .attr('x2', width)
    .attr('y2', "80")

    const makeXLines = () => d3.axisLeft()
    .scale(x)

    svg.append('g')
    .attr('class', 'grid')
    .call(makeXLines()
    .tickSize(-width, 0, 0).tickFormat('')
    )

    var chartData = [
      {name: "Today", color: "#0281fa"},
      {name: "Previous data", color: "#b1d8fd"},
    ];

    //Initialize legend
    var legendItemSize = 12;
    var legendSpacing = 4;
    var xOffset = 15;
    var yOffset = 10;
    var legend = d3
    .select('#legend').attr('height', 30)
    .append('svg')
    .selectAll('.legendItem')
    .data(chartData);

    //Create legend items
    legend
    .enter()
    .append('circle')
    .attr('class', 'legendItem')
    .attr("r", 5)
    .style('fill', d => d.color)
    .attr('transform',
    (d, i) => {
      var x = xOffset + (legendItemSize + legendSpacing) * i * 5;
      var y = yOffset;
      return `translate(${x}, ${y})`;
    })

    //Create legend labels
    legend
    .enter()
    .append('text').style("color", "#64748b")
    .attr('x', (d, i) => 25 + (legendItemSize * 7) * i)
    .attr('y', yOffset + legendSpacing)
    .text(d => d.name).style("font-size", "12px").style("font-weight", "400").style("color", "#475569").style("opacity", "0.6")
  }
}
