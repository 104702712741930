import {Application} from "@hotwired/stimulus"
import {
  Alert,
  Autosave,
  Dropdown,
  Modal,
  Tabs,
  Popover,
  Toggle,
  Slideover
} from "tailwindcss-stimulus-components"

const application = Application.start()

application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('dropdown', Dropdown)
// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

export {application}
