import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    window.onclick = function (event) {
      if (!event.target.matches('.user-account')) {
        var dropdowns = document.getElementsByClassName("user-options")[0];
        if (dropdowns.classList.contains('flex')) {
          dropdowns.classList.add("hidden");
        }
      }
    }
  }

  index = 0;

  dropdown = document.querySelector("#dropdown");
  memberDropdown = document.querySelector("#member-dropdown");

  showModal = () => {
    this.index++;
    const modal = document.querySelector(".modal-overlay");
    if (this.index > 0) {
      modal.classList.replace("hidden", "block")
    }
  }

  showDropdown = () => {
    this.dropdown.classList.replace("hidden", "block")
  }

  showMemberDropdown = () => {
    this.memberDropdown.classList.replace("hidden", "block")
  }

  showStaffDropdown = () => {
    this.memberDropdown.classList.replace("hidden", "block")
  }

  closeModal = () => {
    this.index = this.index - 1;
    const modal = document.querySelector(".modal-overlay");
    if (this.index == 0) {
      modal.classList.replace("block", "hidden")
    }
  }

  selectOption = (event) => {
    this.dropdown.classList.replace("block", "hidden")
    const chooseOption = document.querySelector(".choose-option");
    chooseOption.innerHTML = event.target.innerHTML;
  }

  selectMemberOption = (event) => {
    this.memberDropdown.classList.replace("block", "hidden")
    const chooseMemberOption = document.querySelector(".choose-member");
    chooseMemberOption.innerHTML = event.target.innerHTML;
  }

  showUserOptions = (event) => {
    event.stopPropagation();
    const userOptions = document.querySelector(".user-options");
    userOptions.classList.replace("hidden", "flex");
  }

}
