import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["timezoneSelect"]

  connect() {
    this.setTimezone()
  }

  setTimezone() {
    const now = new Date();
    const timezone_offset = now.getTimezoneOffset();

    // adjusting the sign of offset
    const adjusted_offset = -timezone_offset;

    const options = Array.from(this.timezoneSelectTarget.options)
    const matchingOption = options.find(option => adjusted_offset == option.value)

    if (matchingOption) {
      this.timezoneSelectTarget.value = matchingOption.value
    }
  }
}
