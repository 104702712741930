import {Controller} from "@hotwired/stimulus";

import {Calendar} from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ["calendar", "modal", "start_time", "end_time"]

  connect() {
    let _this = this
    console.log(this.data.get('entriesFeedUrl'))
    console.log(this.data.get('requestsFeedUrl'))
    let calendar = new Calendar(this.calendarTarget, {
      events: this.data.get('entriesFeedUrl'),
      editable: true,
      selectable: true,
      navLinks: true,
      nowIndicator: true,
      headerToolbar: {center: 'dayGridMonth,timeGridWeek,timeGridDay'},
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],

      // this navigates to the day selected from month view -> day view
      navLinkDayClick: function (date, jsEvent) {
        calendar.changeView('timeGridDay', date);
      },

      // This method fires when we select a time slot, or range of slots.
      select: function (info) {

        _this.modalTarget.classList.add('active')
        _this.start_timeTarget.value = info.start
        _this.end_timeTarget.value = info.end

        if (info.allDay = true) {
          _this.allDayTarget.setAttribute('checked', true)
        }

      },

      eventDrop: function (info) {
        let data = _this.data1(info)
        Rails.ajax({
          type: 'PUT',
          url: info.event.url,
          data: new URLSearchParams(data).toString()
        })
      },

      eventResize: function (info) {
        let data = _this.data1(info)

        Rails.ajax({
          type: 'Put',
          url: info.event.url,
          data: new URLSearchParams(data).toString()
        })
      },

      addEvent: function (info) {
        _this.addEvent(info)
      }

    })
    calendar.render()
  }

  createSuccess(event) {

    this.modalTarget.classList.remove('active')
    this.addEvent(event)

  }

  data1(info) {
    return {
      "event[start_time]": info.event.start,
      "event[end_time]": info.event.end,
    }
  }
}
