import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="registration-progress-query"
export default class extends Controller {
  static targets = ["status", "queryStatus"]

  connect() {
    // wait 10 seconds initially
    setInterval(() => this.query(this.queryStatusTarget), 10000);
  }

  query(element) {
    console.log("hello")
    element.click()
  }

  dashboard() {
    window.location = '<%= account_setup_path(:tenant => @registration.subdomain, :token => @registration.auth_token) %>';
  }

}
