import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ['registrationForm', 'registrationTitle', 'registrationSubdomain', 'emailField', 'emailError'];

    static isSubdomainEdited = false;

    connect() {
      this.emailFieldTarget.addEventListener("input", this.validateEmail.bind(this))
    }

    initialize() {
        this.restoreFormSession();
    }

    setFormSession() {
        const formData = new FormData(this.registrationFormTarget);
        const nonEssentialFields = ['g-recaptcha-response', 'authenticity_token', 'registration[subscription_id]'];
        const data = {};

        for (const [key, value] of formData.entries()) {
            if(!nonEssentialFields.includes(key)){
                data[key] = value;
            }
        }

        localStorage.setItem('registration_form', JSON.stringify(data));
    }

    restoreFormSession() {

      const formDataJSON = localStorage.getItem('registration_form');
      if (formDataJSON) {
          const formData = JSON.parse(formDataJSON);
          for (const key in formData) {
              const input = this.registrationFormTarget.querySelector(
                `[name="${key}"]`
              );

              if (input) {
                  input.value = formData[key];
              }
          }
      }
   }

    resetLocalStorage() {
      localStorage.removeItem('registration_form');
    }

    updateSubdomain(){
      if (this.isSubdomainEdited) return;

      const companyTitleField = this.registrationTitleTarget;
      const subdomainField = this.registrationSubdomainTarget;

      subdomainField.value = companyTitleField.value
        .trim()
        .replaceAll(' ', '-');
    }

    setSubdomainEdited(){
      const companyTitleField = this.registrationTitleTarget;
      const subdomainField = this.registrationSubdomainTarget;

      const isDomainNotSameWithCompanyName =
        companyTitleField.value.trim().replaceAll(' ', '-') !==
        subdomainField.value.trim();

      if (subdomainField.value && isDomainNotSameWithCompanyName) {
        this.isSubdomainEdited = true;
      }
    }

    validateEmail() {
      const email = this.emailFieldTarget.value
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  
      if (regex.test(email)) {
        this.emailErrorTarget.classList.add("hidden")
      } else {
        this.emailErrorTarget.classList.remove("hidden")
        this.emailErrorTarget.textContent = "Email is invalid."
      }
    }

}