import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="staff-balance"
export default class extends Controller {
  static targets = ["form"];

  connect() {
  }

  handleFilterChange(el){
    this._submit(el)
  }

  handleLeaveTypeChange(el) {
      this._submit(el)
  }

  _submit(el) {
    if (!el.target.value) return;

    this.formTarget.requestSubmit();
  }
}
