import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['hideable'];

  hideElement() {
    this.hideableTarget.style.display = 'none';
  }
  
  showElement() {
    this.hideableTarget.style.display = '';
  }
}