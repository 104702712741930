import { Controller } from "@hotwired/stimulus";
import { options } from "preact";
import TomSelect from "tom-select";

// Connects to data-controller="cycle-duration-unit"
export default class extends Controller {
    static targets = ["accruals", "duration"]
    connect() {
        let accruals_options = this.accrualsTarget.options
        let cycle_duration_unit = this.durationTarget.selectedOptions[0].value
        this.reset_accruals(cycle_duration_unit, accruals_options, false)
    }
    assign_options_of_accruals(options, cycle_duration_unit) {
        this.accrualsTarget.tomselect.destroy()
        this.reset_accruals(cycle_duration_unit, options)
        new TomSelect(this.accrualsTarget);
    }
    change() {
        let accruals_options = this.accrualsTarget.options
        let cycle_duration_unit = this.durationTarget.selectedOptions[0].value
        this.assign_options_of_accruals(accruals_options, cycle_duration_unit)
    }

    accruals_for_duration(duration) {
        if (duration == 3) {
            return ["Beginning of Year", "End of Year"]
        } else if (duration == 2) {
            return ["Beginning of Month", "End of Month"]
        } else if (duration == 1) {
            return ["Beginning of Week", "End of Week"]
        }
    }

    reset_accruals(duration, options, remove = true) {
        length = options.length
        if (remove) {
            for (let index = 0; index < length; index++) {
                options.remove(0)

            }
        }
        this.accruals_for_duration(duration).forEach(accrual => {
            options.add(new Option(accrual))
        })
    }
}
