import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="registration-progress-query"
export default class extends Controller {

  showProgressBar = () => {
    console.log("show progress")
    document.querySelector(".leave-progress-bar").classList.replace("flex", "hidden")
    const w = 500,
    h = 300,
    r = 100,
    inner = 100 / 2,
    color = d3
    .scaleOrdinal()
    .range([
      "#e6f2ff",
      "#0281fa",
    ]);
    const data = [
      {value: 5, color: "#e6f2ff"},
      {value: 10, color: "#0281fa"},
    ];
    var svg = d3
    .select("#annual-leave")
    .append("div")
    .classed("svg-container", true) //container class to make it responsive
    .append("svg") // Place the chart in 'pie-chart-div'
    .classed("svg-content-responsive", true)
    .attr("width", "100%")
    .attr("height", "100%")

    var vis = svg
    .data([data])
    .attr("width", "100%")
    .attr("height", "100%")
    .attr("viewBox", -w / 2 + " " + -h / 2 + " " + w + " " + h)
    var arc = d3.arc().innerRadius(inner).outerRadius(r);
    var pie = d3.pie().value(function (d) {
      return d.value;
    });

    var arcs = vis
    .selectAll("g.slice")
    .data(pie)
    .enter()
    .append("svg:g")
    .attr("class", "slice")
    arcs
    .append("svg:path")
    .style("fill", function (d, i) {
      return color(i);
    })
    .attr("d", arc);
  }

}
