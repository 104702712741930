import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

// Connects to data-controller="remote-form"
export default class extends Controller {
  static targets = [ "form" ]
  update() {
    Rails.fire(this.formTarget, "submit")
  }
}
