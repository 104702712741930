import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="staff-leave-table"
export default class extends Controller {
  static targets = ["form"];

  toggleFilter() {
    this.formTarget.classList.toggle('hidden');
  }

  toggleRequiresDocumentation(el) {
    this._submit(el)
  }

  filterByDateFrom(el) {
    this._submit(el)
  }

  filterByDateTo(el) {
    this._submit(el)
  }

  filterByLeaveType(el) {
    this._submit(el)
  }

  filterByStatus(el) {
    this._submit(el)
  }

  filterByEmployee(el) {
    this._submit(el)
  }

  filterByLocations(el) {
    this._submit(el)
  }

  filterByDepartments(el) {
    this._submit(el)
  }

  filterByEmployeeStatus(el) {
      this._submit(el)
  }

  _submit(el) {
    if(!el.target.value) return;
    
    this.formTarget.requestSubmit();
  }
}
